.theme-2024050,
.theme-monitor\.watchmedier\.dk,
.theme-2025030,
.theme-byrummonitor\.dk,
.theme-2024100,
.theme-uddannelsesmonitor\.dk,
.theme-2025035,
.theme-skolemonitor\.dk,
.theme-2025037,
.theme-sundhedsmonitor\.dk,
.theme-2025032,
.theme-klimamonitor\.dk,
.theme-2025033,
.theme-kulturmonitor\.dk,
.theme-2025031,
.theme-idraetsmonitor\.dk,
.theme-2025038,
.theme-turistmonitor\.dk,
.theme-2025036,
.theme-socialmonitor\.dk,
.theme-2025034,
.theme-seniormonitor\.dk,
.theme-2025039,
.theme-monitormedier\.dk {
  --font-size-xs: 0.675rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.3125rem;
  --font-size-3xl: 1.375rem;
  --font-size-4xl: 1.75rem;
  --font-size-5xl: 2rem;
  --font-size-6xl: 2.625rem;
  --font-size-7xl: 2.875rem;
  --font-size-8xl: 3rem;

  --line-height-xs: 1.15;
  --line-height-sm: 1.15;
  --line-height-base: 1.15;
  --line-height-lg: 1.15;
  --line-height-xl: 1.25;
  --line-height-2xl: 1.1;
  --line-height-3xl: 1.1;
  --line-height-4xl: 1.05;
  --line-height-5xl: 1.05;
  --line-height-6xl: 1.05;
  --line-height-7xl: 1.05;
  --line-height-8xl: 1.05;

  --letter-spacing-xs: 0.045em;

  --theme-bold: 500;
  --theme-heading-weight: 700;

  --font-body: var(--font-family-flama);
  --font-heading: var(--font-family-capitolium);
  --theme-font-article-body: var(--font-heading);
  --theme-font-article-heading: var(--font-body);

  --theme-color-highlight: 44 93% 48%;
  --theme-color-muted: var(--theme-color-primary-hover);

  --theme-color-accent: var(--theme-color-primary-hover);
  --theme-color-accent-hover: from hsl(var(--theme-color-accent)) h s
    calc(l - 5);

  --theme-color-secondary-hover: from hsl(var(--theme-color-secondary-base)) h s
    calc(l - 5);

  --theme-grid-xs: 1px;
  --theme-grid-lg: 5px;

  --theme-grid-primary-color: 0 0% 0%;
  --theme-grid-secondary-color: 0 0% 80%;

  --theme-font-size-badge: 0.625rem;
  --theme-letter-spacing-badge: 0.0625rem;
}

.theme-2025030,
.theme-byrummonitor\.dk {
  --theme-color-primary-base: 220.4 47.9% 37.6;
  --theme-color-primary-hover: 213.3 100 18;

  --theme-color-secondary-base: 221.3 55.2% 88.6%;
  --theme-color-muted: 220 44% 19%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025035,
.theme-skolemonitor\.dk {
  --theme-color-primary-base: 170 100% 23.5%;
  --theme-color-primary-hover: 170.1 100% 16.7%;

  --theme-color-secondary-base: 171.4 32.6% 83.1%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025037,
.theme-sundhedsmonitor\.dk {
  --theme-color-primary-base: 339.7 74.7% 31%;
  --theme-color-primary-hover: 344.1 100% 17.1%;

  --theme-color-secondary-base: 340 27.9% 83.1%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025032,
.theme-klimamonitor\.dk {
  --theme-color-primary-base: 187.1 36.7% 27.3%;
  --theme-color-primary-hover: 185.7 100% 10.4%;

  --theme-color-secondary-base: 186.8 40% 78.4%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025033,
.theme-kulturmonitor\.dk {
  --theme-color-primary-base: 44.9 70.9% 41.8%;
  --theme-color-primary-hover: 45.7 100% 25.5%;

  --theme-color-secondary-base: 48.9 87.1% 69.6%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025031,
.theme-idraetsmonitor\.dk {
  --theme-color-primary-base: 17.4 81% 39.2%;
  --theme-color-primary-hover: 17.4 88.7% 31.2%;

  --theme-color-secondary-base: 17.8 89.4% 74.1%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025038,
.theme-turistmonitor\.dk {
  --theme-color-primary-base: 0 84.8% 66.5%;
  --theme-color-primary-hover: 0 63.5% 50.6%;

  --theme-color-secondary-base: 0 100% 90%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025036,
.theme-socialmonitor\.dk {
  --theme-color-primary-base: 290.6 54.2% 23.1%;
  --theme-color-primary-hover: 299 80.8% 14.3%;

  --theme-color-secondary-base: 287 20.4% 77.8%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025034,
.theme-seniormonitor\.dk {
  --theme-color-primary-base: 359 46.8% 24.3%;
  --theme-color-primary-hover: 358.9 77.5% 13.9%;

  --theme-color-secondary-base: 357.6 35.3% 72.7%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-uddannelsesmonitor\.dk,
.theme-2024100 {
  --theme-color-primary-base: 120 79% 17%; /* #094E09 */
  --theme-color-primary-hover: 120 82% 13%; /* #063E06 */

  --theme-color-secondary-base: 120 17% 75%; /* #B5CAB5 */

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-2025039,
.theme-monitormedier\.dk {
  --theme-color-primary-base: 0 0% 0%; /* #000000 */
  --theme-color-primary-hover: 0 0% 20%; /* # CHANGE ME IF NEEDED */

  --theme-color-secondary-base: 0 0% 80%; /* #CCCCCC */

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}
