:root {
  --font-family-flama: "Flama", sans-serif;
}

@font-face {
  font-family: "Flama";
  src: url("./Flama-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Flama";
  src: url("./Flama-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Flama";
  src: url("./Flama-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
