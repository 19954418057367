.theme-2022100,
.theme-kforum\.dk {
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.8125rem;
  --font-size-base: 1.125rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.375rem;
  --font-size-4xl: 1.75rem;
  --font-size-5xl: 2rem;
  --font-size-6xl: 2.5rem;
  --font-size-7xl: 2.5rem;
  --font-size-8xl: 2.5rem;

  --line-height-xs: 1.5;
  --line-height-sm: 1.4;
  --line-height-base: 1.3333;
  --line-height-lg: 1.5;
  --line-height-xl: 1.25;
  --line-height-2xl: 1.167;
  --line-height-3xl: 1.15;
  --line-height-4xl: 1.15;
  --line-height-5xl: 1.15;
  --line-height-6xl: 1.1;
  --line-height-7xl: 1.1;
  --line-height-8xl: 1.1;

  --theme-bold: 900;
  --theme-heading-weight: 900;

  --font-body: var(--font-family-montserrat);
  --font-heading: var(--font-family-atlas-grotesk);
  --theme-font-article-body: var(--font-body);
  --theme-font-article-heading: var(--font-heading);

  --theme-color-primary-base: 248 80% 48%;
  --theme-color-primary-hover: 248 80% 36%;

  --theme-color-secondary-base: 357 68% 45%;
  --theme-color-secondary-hover: 357 65% 35%;

  --theme-color-highlight: var(--theme-color-secondary-base);
  --theme-color-muted: var(--theme-color-primary-base);

  --theme-color-accent: var(--theme-color-secondary-base);
  --theme-color-accent-hover: var(--theme-color-secondary-hover);

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);

  --theme-color-site-logo-gradient-from: var(--theme-color-secondary-base);
  --theme-color-site-logo-gradient-to: var(--theme-color-primary-base);

  --theme-grid-xs: 3px;
  --theme-grid-lg: 3px;

  --theme-grid-primary-color: 248 80% 48%;
  --theme-grid-secondary-color: 248 80% 48%;

  --theme-font-size-badge: var(--font-size-xs);
}
