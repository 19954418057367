/**
 * All colours are defined in HSL, but the `hsl()` colour function has been
 * omitted to allow Tailwind CSS to enable the opacity modifier:
 * https://tailwindcss.com/docs/customizing-colors#using-css-variables
 */

.theme-advokatwatch\.dk,
.theme-advokatwatch\.no,
.theme-agriwatch\.dk,
.theme-amwatch\.dk,
.theme-amwatch\.com,
.theme-ctwatch\.dk,
.theme-2023070,
.theme-detailwatch\.dk,
.theme-handelswatch\.no,
.theme-eiendomswatch\.no,
.theme-ejendomswatch\.dk,
.theme-2022091,
.theme-energiwatch\.no,
.theme-energiwatch\.dk,
.theme-energywatch\.com,
.theme-energywatch\.eu,
.theme-2024030,
.theme-finanswatch\.se,
.theme-finanswatch\.dk,
.theme-finanswatch\.no,
.theme-finanzbusiness\.de,
.theme-2024020,
.theme-matvarewatch\.no,
.theme-fodevarewatch\.dk,
.theme-itwatch\.dk,
.theme-kapwatch\.dk,
.theme-mediawatch\.dk,
.theme-2022090,
.theme-medwatch\.no,
.theme-medwatch\.com,
.theme-medwatch\.dk,
.theme-mobilitywatch\.dk,
.theme-policywatch\.dk,
.theme-shippingwatch\.com,
.theme-shippingwatch\.dk,
.theme-2024010,
.theme-watchmedia\.no,
.theme-watchmedier\.dk {
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.3125rem;
  --font-size-3xl: 1.375rem;
  --font-size-4xl: 1.75rem;
  --font-size-5xl: 2rem;
  --font-size-6xl: 2.625rem;
  --font-size-7xl: 2.875rem;
  --font-size-8xl: 3rem;

  --line-height-xs: 1rem;
  --line-height-sm: 1.25rem;
  --line-height-base: 1.5rem;
  --line-height-lg: 1.6875rem;
  --line-height-xl: 1.6875rem;
  --line-height-2xl: 1.6875rem;
  --line-height-3xl: 1.75rem;
  --line-height-4xl: 2rem;
  --line-height-5xl: 2.5rem;
  --line-height-6xl: 3rem;
  --line-height-7xl: 3.25rem;
  --line-height-8xl: 3.5rem;

  --theme-bold: 700;
  --theme-heading-weight: 700;

  --font-body: var(--font-family-montserrat);
  --font-heading: var(--font-family-publico-headline);
  --theme-font-article-body: var(--font-body);
  --theme-font-article-heading: var(--font-heading);

  /** Secondary fallback for brands that do not have one */
  --theme-color-secondary-base: 206 14% 20%;
  --theme-color-secondary-hover: 206 14% 5%;

  --theme-color-highlight: 44 93% 48%;
  --theme-color-muted: 206 14% 20%;

  --theme-color-accent: var(--theme-color-secondary-base);
  --theme-color-accent-hover: var(--theme-color-secondary-hover);

  /** This is colors for the mother brand e.g. Watchmedier/Watchmedia */
  --theme-color-brand-logo-primary: 206 14% 20%;
  --theme-color-brand-logo-secondary: 206 5% 71%;

  --theme-grid-xs: 1px;
  --theme-grid-lg: 5px;

  --theme-grid-primary-color: 0 0% 0%;
  --theme-grid-secondary-color: 0 0% 80%;

  --theme-font-size-badge: var(--font-size-xs);
}

.theme-advokatwatch\.dk,
.theme-advokatwatch\.no {
  --theme-color-primary-base: 195 67% 30%;
  --theme-color-primary-hover: 195 68% 22%;

  --theme-color-secondary-base: 206 14% 20%;
  --theme-color-secondary-hover: 195 68% 22%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-agriwatch\.dk {
  --theme-color-primary-base: 192 45% 36%;
  --theme-color-primary-hover: 192 45% 24%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-amwatch\.dk,
.theme-amwatch\.com {
  --theme-color-primary-base: 350 59% 36%;
  --theme-color-primary-hover: 350 59% 24%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-ctwatch\.dk {
  --theme-color-primary-base: 206 93% 73%;
  --theme-color-primary-hover: 206 93% 64%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-2023070,
.theme-detailwatch\.dk,
.theme-handelswatch\.no {
  --theme-color-primary-base: 22 85% 52%;
  --theme-color-primary-hover: 22 85% 40%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-eiendomswatch\.no,
.theme-ejendomswatch\.dk {
  --theme-color-primary-base: 177 80% 20%;
  --theme-color-primary-hover: 176 80% 8%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-2022091,
.theme-energiwatch\.no,
.theme-energiwatch\.dk,
.theme-energywatch\.com,
.theme-energywatch\.eu {
  --theme-color-primary-base: 200 34% 46%;
  --theme-color-primary-hover: 200 34% 36%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-2024030,
.theme-finanswatch\.se,
.theme-finanswatch\.dk,
.theme-finanswatch\.no,
.theme-finanzbusiness\.de {
  --theme-color-primary-base: 42 78% 53%;
  --theme-color-primary-hover: 42 78% 44%;

  --theme-color-secondary-base: 211 43% 21%;
  --theme-color-secondary-hover: 211 44% 12%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);

  --theme-color-brand-logo-primary: var(--theme-color-secondary-base);
}

.theme-2024020,
.theme-matvarewatch\.no,
.theme-fodevarewatch\.dk {
  --theme-color-primary-base: 358 57% 32%;
  --theme-color-primary-hover: 358 57% 20%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-itwatch\.dk {
  --theme-color-primary-base: 158 88% 37%;
  --theme-color-primary-hover: 158 87% 28%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-kapwatch\.dk {
  --theme-color-primary-base: 346 77% 36%;
  --theme-color-primary-hover: 346 77% 24%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-mediawatch\.dk {
  --theme-color-primary-base: 192 100% 41%;
  --theme-color-primary-hover: 192 100% 32%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-2022090,
.theme-medwatch\.no,
.theme-medwatch\.com,
.theme-medwatch\.dk {
  --theme-color-primary-base: 78 48% 42%;
  --theme-color-primary-hover: 78 48% 32%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-mobilitywatch\.dk {
  --theme-color-primary-base: 182 96% 31%;
  --theme-color-primary-hover: 182 96% 20%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-policywatch\.dk {
  --theme-color-primary-base: 183 100% 20%;
  --theme-color-primary-hover: 183 100% 8%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-primary);
}

.theme-shippingwatch\.com,
.theme-shippingwatch\.dk {
  --theme-color-primary-base: 12 98% 40%;
  --theme-color-primary-hover: 12 99% 28%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-primary-base);
}

.theme-2024010,
.theme-watchmedia\.no,
.theme-watchmedier\.dk {
  --theme-color-primary-base: 206 14% 20%;
  --theme-color-primary-hover: 204 12% 8%;

  --theme-color-site-logo-primary: var(--theme-color-brand-logo-primary);
  --theme-color-site-logo-secondary: var(--theme-color-brand-logo-secondary);
}
